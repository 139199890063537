<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "liste des clients",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "liste",
          active: true,
        },
      ],

      idClient: 0,
      dataUser: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,

      ordersData: [],
      fields: [
        { key: "nom", sortable: true, label: "Nom & Prénom" },
        { key: "designation", sortable: true, label: "Designation" },
        { key: "type", sortable: true, label: "Type client" },
        { key: "contact", sortable: true, label: "Contact" },
        { key: "action", sortable: true, label: "Actions" },
      ],
      showDesactiveModal: false,
      showActiveModal: false,
      showEditModal: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      userToUse: 0,
      file: null,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      nom: "",
      prenom: "",
      ville: "",
      contact: "",
      email: "",
      designation: "",
      ifu: "",
      imageCode: "",
      estEntreprise: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/entreprise/client",
        undefined,
        false
      );
      if (data && data.data) {
        console.log("data.data:", data.data);
        const formattedTable = data.data.clientActif.map((user) => {
          return {
            id: user.id,
            nom: user.estEntreprise ? "" : user.nom + " " + user.prenom,
            designation: user.estEntreprise ? user.designation : "",
            prenom: user.prenom,
            contact: user.contact,
            type: user.estEntreprise ? "ENTREPRISE" : "PARTICULIER",
          };
        });

        const formattedTableI = data.data.clientInActif.map((user) => {
          return {
            id: user.id,
            nom: user.estEntreprise ? "" : user.nom + " " + user.prenom,
            designation: user.estEntreprise ? user.designation : "",
            prenom: user.prenom,
            contact: user.contact,
            type: user.estEntreprise ? "ENTREPRISE" : "PARTICULIER",
          };
        });
        this.dataUser = formattedTable;
        this.dataUserD = formattedTableI;
      }

      // Set the initial number of items
      this.totalRows = this.dataUser.length;
      this.nbreUser = this.dataUser.length;
    },
    async editClientSubmit() {
      const editData = await apiRequest(
        "POST",
        "admin/entreprise/client-update",
        {
          id: this.idClient,
          nom: this.nom,
          prenom: this.prenom,
          ville: this.ville,
          contact: this.contact,
          email: this.email,
          designation: this.designation,
          ifu: this.ifu,
          imageCode: "",
          estEntreprise: this.estEntreprise,
        },
        false
      );

      if (editData && editData.data) {
        this.showSucessEdit = true;
        this.init();
        this.showEditModal = false;
      } else {
        this.showEchecEdit = true;
        this.init();
        this.showEditModal = false;
      }
    },
    async editClient(row) {
      this.showEditModal = true;
      this.idClient = row.id;

      const dataDetail = await apiRequest(
        "GET",
        "admin/entreprise/detail-client/?id=" + this.idClient,
        {},
        false
      );

      if (dataDetail && dataDetail.data) {
        let detail = dataDetail.data;
        //console.log("Detail User", dataDetail.data)
        this.nom = detail.nom;
        this.prenom = detail.prenom;
        this.ville = detail.ville;
        this.contact = detail.contact;
        this.email = detail.email;
        this.designation = detail.designation;
        this.ifu = detail.ifu;
        this.estEntreprise = detail.estEntreprise;
      }
    },
    desactiveClient(row) {
      this.idClient = row.id;
      this.showDesactiveModal = true;
    },
    activeClient(row) {
      this.idClient = row.id;
      this.showActiveModal = true;
    },
    async desactiveClientSubmit() {
      this.showDesactiveModal = false;
      const data = await apiRequest(
        "POST",
        "admin/entreprise/action-client",
        { idClient: this.idClient, active: false },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    async activeClientSubmit() {
      const data = await apiRequest(
        "POST",
        "admin/entreprise/action-client",
        { idClient: this.idClient, active: true },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.position();
        this.init();
        this.showActiveModal = false;
      }
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      console.log("row:", row);
      router.push(`/client/details/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Clients actifs</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6"></div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="dataUser"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editClient(row.item)"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="desactiveClient(row.item)"
                        title="Supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                      <!--<a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="desactiveClient(row.item)"
                        title="désactiver"
                      >
                        <i class="mdi mdi-close font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="messageShow(row.item)"
                        title="Mail"
                      >
                        <i class="fas fa-envelope font-size-16"></i>
                      </a>-->
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Clients inactifs</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6"></div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="dataUserD"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="activeClient(row.item)"
                        title="Activer"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                      </a>
                      <!--<a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="desactiveClient(row.item)"
                        title="désactiver"
                      >
                        <i class="mdi mdi-close font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="messageShow(row.item)"
                        title="Mail"
                      >
                        <i class="fas fa-envelope font-size-16"></i>
                      </a>-->
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDesactiveModal"
      title="Voulez-vous vraiment supprimer ce client ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDesactiveModal = false"
          >Non</b-button
        >
        <b-button
          variant="success"
          class="ml-3 mr-4"
          @click="desactiveClientSubmit"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showActiveModal"
      title="Voulez-vous vraiment activer ce client ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showActiveModal = false"
          >Non</b-button
        >
        <b-button
          variant="success"
          class="ml-3 mr-4"
          @click="activeClientSubmit"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="L'envoie a échoué"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucessEdit"
      title="Modification effectuée!!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="success" @click="showSucessEdit = false"
          >OK</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchecEdit"
      title="Echec de la modification"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecEdit = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEditModal"
      title="Editer un client"
      title-class="font-18"
      hide-footer
    >
      <form enctype="multipart/form-data" @submit.prevent="submitEditClient">
        <div class="mt-2">
          <div class="row ">
                  <div class="col-md-1">
                    <label for="check"
                      >Entreprise</label
                    >
                    </div>
                    <div class="col-md-1">
                    <input
                      type="checkbox"
                      v-model="estEntreprise"
                      class="form-control"
                      id="check"
                    />
                  </div>
                </div>
          <div class="row mt-2"  v-if="!estEntreprise">
            <div class="col-md-6">
              <label for="">Nom</label>
              <input type="text" class="form-control" v-model="nom" />
            </div>
            <div class="col-md-6">
              <label for="">Prénom</label>
              <input type="text" class="form-control" v-model="prenom" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-6">
              <label for="">Ville de résidence</label>
              <input type="text" class="form-control" v-model="ville" />
            </div>
            <div class="col-md-6">
              <label for="">Contact</label>
              <input type="text" class="form-control" v-model="contact" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-6">
              <label for="">Email</label>
              <input type="email" class="form-control" v-model="email" />
            </div>
          </div>
          <div class="row mt-4"  v-if="estEntreprise">
            <div class="col-md-6">
              <label for="">Désignation</label>
              <input type="text" class="form-control" v-model="designation" />
            </div>
            <div class="col-md-6">
              <label for="">IFU</label>
              <input type="text" class="form-control" v-model="ifu" />
            </div>
          </div>
          <div class="float-right mt-4">
            <button class="btn btn-primary" @click="editClientSubmit">
              Ajouter
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
